import React from 'react';
import PropTypes from 'prop-types';

const NeighborhoodCard = props => {
  const {
    neighborhood,
    region,
  } = props;

  const {
    activeListingCount,
    activeMateCount,
    activeRoomCount,
    image,
    url,
    name: hoodName,
    description,
  } = neighborhood;

  return (
    <>
      { neighborhood
      && (
        <>
          <hr />
          <div className="neighborhood-jumbotron">
            <h5 className="neighborhood-title">The Neighborhood</h5>
            <div className="neighborhood-img" style={{ backgroundImage: `url(${image})` }} />
            <h3 className="neighborhood-text">
              {`${hoodName}, ${region.name}`}
            </h3>
            <p>
              {description}
            </p>
            <div className="neighborhood-info">{`${activeListingCount} apartments  •  ${activeRoomCount} rooms  •  ${Number(activeMateCount)} roommates`}</div>
            <a className="button btn-rounded btn-transparent neighborhood-button" href={url}>
              See the rest of the neighborhood
              <i className="nookons-chevron-right pl-3" />
            </a>
          </div>
        </>
      )}
    </>
  );
};

NeighborhoodCard.propTypes = {
  neighborhood: PropTypes.shape({
    activeListingCount: PropTypes.number,
    activeMateCount: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
    activeRoomCount: PropTypes.number,
    image: PropTypes.string,
    url: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
  region: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
};

export default NeighborhoodCard;
