import axios from 'axios';


//ACTION TYPES
const GET_LIKED_LOCATIONS = 'GET_LIKED_LOCATIONS';
const UNLIKE_LOCATION = 'UNLIKE_LOCATION';
const LIKE_LOCATION = 'LIKE_LOCATION';


// ACTION CREATORS
const getLocationLikes = likes => ({ type: GET_LIKED_LOCATIONS, likes });
const likeLocation = locationId => ({ type: LIKE_LOCATION, locationId });
const unlikeLocation = locationId => ({ type: UNLIKE_LOCATION, locationId });


// THUNK CREATORS
export function fetchLocationLikes(){
  return function thunk(dispatch) {
    return axios.get('/current/liked_locations')
    .then(res => res.data)
    .then(likes => {
      dispatch(getLocationLikes(likes))
    })
    .catch(error => console.log(error))
  };
};

export function dispatchLike(locationId, locationUrl){
  return function thunk(dispatch) {
    return axios.get(`/locations/${locationUrl}/like.json`)
        .then(res => {
          if (!res.request.responseURL.includes(`${locationId}`)){
            window.location.href = "/login";
          } else {
            dispatch(likeLocation(locationId));
          }
        })
        .catch(error => {
          console.log(error);
        })
  }
};

export function dispatchUnlike(locationId, locationUrl){
  return function thunk(dispatch) {
    return axios.get(`/locations/${locationUrl}/unlike.json`)
        .then(res => dispatch(unlikeLocation(locationId)))
        .catch(error => console.log(error))
  }
};


// REDUCER
export default function reducer( state = [], action){
  switch (action.type) {
    case GET_LIKED_LOCATIONS:
      return action.likes;
    case LIKE_LOCATION:
      return [...state, action.locationId];
    case UNLIKE_LOCATION:
      return state.filter(id => id !== action.locationId);
    default:
      return state;
  }
};
