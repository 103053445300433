import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { connect } from 'react-redux';
import { setStore } from 'reducers/matrixListings';
import LoadingSpinner from 'components/LoadingSpinner';
import MatrixListingCard from 'components/listings/cards/Matrix';
import { fetchMatrixListings } from 'api/listing';
import { fetchCurrentAgent } from 'api/agents';

const ListMatrixListings = props => {
  const {
    editable,
    bypass_categorized: bypassCategorized,
    listingIds,
    _setStore,
  } = props;

  const [loading, setLoading] = useState(true);
  const [listingsDisplayed, setListingsDisplayed] = useState([]);
  const [readOnly, setReadOnly] = useState(true);

  useEffect(() => {
    fetchCurrentAgent().then(agent => {
      if (agent.current) {
        const cancelToken = axios.CancelToken.source().token;
        setReadOnly(agent.current.readOnly);
        axios
          .get(`/listing_collections/${agent.current.agentId}/listcollect.json`, {
            cancelToken,
          })
          .then(res => res.data)
          .then(listingCollections => {
            _setStore({ listingCollections });
          });
      }
    });
  }, []);

  useEffect(() => {
    if (Array.isArray(listingIds) && listingIds.length > 0) {
      fetchMatrixListings(listingIds).then(fetchedListings => {
        setListingsDisplayed(fetchedListings || []);
        setLoading(false);
      });
    }
  }, [listingIds]);

  if (loading) {
    return (
      <div>
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <div>
      {listingsDisplayed.map(listing => (
        <MatrixListingCard
          className="container new-matrix-card"
          listing={listing}
          key={`listing-${listing.id}`}
          edit={editable && !readOnly}
          bypassCategorized={bypassCategorized}
          readOnly={readOnly}
        />
      ))}
    </div>
  );
};

ListMatrixListings.defaultProps = {
  editable: true,
  bypass_categorized: false,
  read_only: true,
  listingIds: [],
};

ListMatrixListings.propTypes = {
  listingIds: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
  ),
  bypass_categorized: PropTypes.bool,
  editable: PropTypes.bool,
  _setStore: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  _setStore: payload => dispatch(setStore(payload)),
});

const ConnectedListings = connect(
  null,
  mapDispatchToProps,
)(ListMatrixListings);

export default ConnectedListings;
