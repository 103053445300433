import React from 'react';
import strftime from 'strftime';
import PropTypes from 'prop-types';
import { getTrueDate, present } from 'helpers/util';
import MatrixField from './MatrixField';

const MatrixListingSecondCol = props => {
  const {
    listing,
    edit,
    changeField,
  } = props;

  const {
    salesAgent,
    listingAgent,
    creator,
    createdBy,
    network,
  } = listing;

  const moveInCost = network ? (present(listing.moveInCost) ? listing.moveInCost : 'N/A') : '';
  const listingAgentUrl = listingAgent.fullName && listingAgent.id
    ? `/agents/${listingAgent.id}-${listingAgent.fullName
      .toLowerCase()
      .split(' ')
      .join('-')}`
    : '';

  return (
    <div className="col-lg-4 listing-first-data-col">
      <div className="matrix-listing-card-section">
        <div className="matrix-listing-card-header border-0">
          People
        </div>
        {!listing.subwayListing && (
          <>
            <div className="matrix-listing-card-row row">
              <div className="col-6 label">Listing agent</div>
              <div className="col-6 value">
                <a href={listingAgentUrl}>{listingAgent.fullName}</a>
                <p>{listingAgent.phone}</p>
              </div>
            </div>
            <div className="matrix-listing-card-row row">
              <div className="col-6 label">Sales agent</div>
              <div className="col-6 value">
                <a href={`/agents/${salesAgent.id}`}>{salesAgent.fullName}</a>
                <p>{salesAgent.phone}</p>
              </div>
            </div>
            <div className="matrix-listing-card-row row">
              <div className="col-6 label">Created by</div>
              <div className="col-6 value">
                {creator.firstName}
                {creator.idVerified && creator.licenseVerified && (
                  <>
                    &nbsp;
                    <i className="nookons-check-circle-fill" />
                  </>
                )}
              </div>
            </div>
          </>
        )}
        {listing.subwayListing && createdBy && (
          <>
            <div className="matrix-listing-card-row row">
              <div className="col-6 label">Created by</div>
              <div className="col-6 value">
                {createdBy.fullName}
              </div>
            </div>
            <div className="matrix-listing-card-row row">
              <div className="col-6 label">Email</div>
              <div className="col-6 value">
                {createdBy.email}
              </div>
            </div>
            <div className="matrix-listing-card-row row">
              <div className="col-6 label">ID</div>
              <div className="col-6 value">
                {createdBy.id}
              </div>
            </div>
            <div className="matrix-listing-card-row row">
              <div className="col-6 label">Last Signed In</div>
              <div className="col-6 value">
                {createdBy.lastSignInAt && strftime('%D', getTrueDate(createdBy.lastSignInAt))}
              </div>
            </div>
            <div className="matrix-listing-card-row row">
              <div className="col-6 label">Verification Submitted At</div>
              <div className="col-6 value">
                {createdBy.stateIdSubmittedAt && strftime('%D', getTrueDate(createdBy.stateIdSubmittedAt))}
              </div>
            </div>
            <div className="matrix-listing-card-row row">
              <div className="col-6 label">Verified At</div>
              <div className="col-6 value">
                {createdBy.stateIdApprovedAt && strftime('%D', getTrueDate(createdBy.stateIdApprovedAt))}
              </div>
            </div>
          </>
        )}
      </div>
      <div className="matrix-listing-card-section">
        <div className="matrix-listing-card-header">
          Lease
        </div>
        <div className="matrix-listing-card-row row">
          <div className="col-6 label">Available on</div>
          <MatrixField
            edit={edit || null}
            changeField={changeField}
            myValue={listing.dateAvailable ? listing.dateAvailable : ''}
            editAttr="date_available"
            listingId={listing.id}
            myClass="col-6 value"
          >
            {listing.dateAvailable ? strftime('%B %e, %Y', getTrueDate(listing.dateAvailable, 'yearFirst')) : ''}
          </MatrixField>
        </div>
        <div className="matrix-listing-card-row row">
          <div className="col-6 label">Utilities</div>
          <MatrixField
            edit={edit || null}
            changeField={changeField}
            myValue={listing.utilities ? listing.utilities : ''}
            editAttr="utilities"
            listingId={listing.id}
            myClass="col-6 value"
          >
            {listing.utilities && listing.utilities.length > 0
              ? listing.utilities
              : ''}
          </MatrixField>
        </div>
        <div className="matrix-listing-card-row row">
          <div className="col-6 label">Term</div>
          <MatrixField
            edit={edit || null}
            changeField={changeField}
            myValue={listing.term ? listing.term : ''}
            editAttr="term"
            listingId={listing.id}
            myClass="col-6 value"
          >
            {listing.term && listingAgent.fullName
              ? listing.term
              : listingAgent.fullName
              && (`Ask ${listingAgent.fullName}`)}
          </MatrixField>
        </div>
        {!listing.subwayListing && (
          <div className="matrix-listing-card-row row">
            <div className="col-6 label">Move in cost</div>
            <div className="col-6 value">{!!moveInCost && moveInCost.join(', ')}</div>
          </div>
        )}
        <div className="matrix-listing-card-row row">
          <div className="col-6 label">Pets</div>
          <div className="col-6 value">{!!listing.pets && listing.pets}</div>
        </div>
      </div>
      <div className="matrix-listing-card-section">
        <div className="matrix-listing-card-header">
          Space
        </div>
        <div className="matrix-listing-card-row row">
          <div className="col-6 label">Sq. Feet</div>
          <MatrixField
            edit={edit || null}
            changeField={changeField}
            myValue={
              listing.squareFeet ? listing.squareFeet : ''
            }
            editAttr="square_feet"
            listingId={listing.id || ''}
            myClass="col-6 value"
          >
            {!!listing.squareFeet && listing.squareFeet}
            {listing.superAdmin
              && listing
              && !listing.squareFeet && (
                <span className="label label-outline pr-1">
                  No square feet
                </span>
            )}
          </MatrixField>
        </div>
        <div className="matrix-listing-card-row row">
          <div className="col-6 label">Floorplan</div>
          <div className="col-6 value">
            {(listing.floorplan && listing.floorplan.url) ? (
              <a
                href={listing.floorplan.url}
                target="_blank"
                rel="noreferrer noopener"
              >
                Click to view
              </a>
            ) : (
              <span>No Floorplan Uploaded</span>
            )}
          </div>
        </div>
        <div className="matrix-listing-card-row row">
          <div className="col-6 label">Video</div>
          <div className="col-6 value">
            {listing.youtubeUrl ? (
              <a
                href={listing.youtubeUrl}
                rel="noreferrer"
                target="_blank"
              >
                Open video
              </a>
            ) : (
              'N/A'
            )}

          </div>
        </div>
      </div>
    </div>
  );
};

MatrixListingSecondCol.defaultProps = {
  listing: null,
  edit: false,
  changeField: () => {},
};

MatrixListingSecondCol.propTypes = {
  listing: PropTypes.shape({
    moveInCost: PropTypes.arrayOf(PropTypes.string),
    listingAgent: PropTypes.shape({
      fullName: PropTypes.string,
      id: PropTypes.number,
      phone: PropTypes.string,
    }),
    salesAgent: PropTypes.shape({
      fullName: PropTypes.string,
      id: PropTypes.number,
      phone: PropTypes.string,
    }),
    creator: PropTypes.shape({
      firstName: PropTypes.string,
      idVerified: PropTypes.bool,
      licenseVerified: PropTypes.bool,
    }),
    createdBy: PropTypes.shape({
      fullName: PropTypes.string,
      id: PropTypes.number,
      email: PropTypes.string,
      lastSignInAt: PropTypes.string,
      stateIdSubmittedAt: PropTypes.string,
      stateIdApprovedAt: PropTypes.string,
    }),
    subwayListing: PropTypes.bool,
    dateAvailable: PropTypes.string,
    id: PropTypes.number,
    utilities: PropTypes.string,
    term: PropTypes.string,
    network: PropTypes.string,
    squareFeet: PropTypes.number,
    superAdmin: PropTypes.bool,
    floorplan: PropTypes.shape({
      url: PropTypes.string,
    }),
    youtubeUrl: PropTypes.string,
    pets: PropTypes.string,
  }),
  edit: PropTypes.bool,
  changeField: PropTypes.func,
};

export default MatrixListingSecondCol;
