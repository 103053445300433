import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  fetchLocationLikes,
  dispatchLike,
  dispatchUnlike,
} from '../reducers/likedLocations';
import { fetchLocationLikesTrue } from '../reducers/fetchStatus';
import { fetchCurrentUserTrue, fetchCurrentUser } from '../reducers/auth';
import AuthModal from '../components/modal/AuthModal';
import { Heart } from '../components/Heart';

class LocationLikeButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      locationId: props.id,
      locationSlug: props.slug,
      buttonStatus: 'unlike-button',
    };
    this.toggleLike = this.toggleLike.bind(this);
  }

  componentDidMount() {
    if (!this.props.currentUserFetched) {
      this.props.fetchCurrentUser();
      this.props.fetchCurrentUserTrue();
    }
    if (!this.props.fetchStatus.likedLocationsFetch) {
      this.props.fetchLocationLikes();
      this.props.fetchLocationLikesTrue();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.likedLocations.includes(this.state.locationId)) {
      this.setState({ buttonStatus: 'like-button' });
    }
  }

  toggleLike(e) {
    const locationId = this.state.locationId;
    const locationSlug = this.state.locationSlug;

    e.preventDefault();
    if (this.state.buttonStatus === 'unlike-button') {
      this.setState({ buttonStatus: 'like-button' });
      this.props.dispatchLike(locationId, `${locationId}-${locationSlug}`);
    } else if (this.state.buttonStatus === 'like-button') {
      this.setState({ buttonStatus: 'unlike-button' });
      this.props.dispatchUnlike(locationId, `${locationId}-${locationSlug}`);
    }
  }

  render() {
    const buttonStatus = this.state.buttonStatus;

    return (
      <div>
        {this.props.signed_in ? (
          <Heart
            handleClick={this.toggleLike}
            buttonStatus={this.state.buttonStatus}
          />
        ) : (
          <AuthModal>
            <Heart buttonStatus="unlike-button" />
          </AuthModal>
        )}
      </div>
    );
  }
}

const mapState = state => ({
  likedLocations: state.likedLocations || [],
  fetchStatus: state.fetchStatus || [],
  currentUserFetched: state.auth.currentUserFetched,
  signed_in: state.auth.signed_in,
});

const mapDispatch = dispatch => ({
  fetchLocationLikes() {
    dispatch(fetchLocationLikes());
  },
  fetchLocationLikesTrue() {
    dispatch(fetchLocationLikesTrue());
  },
  dispatchLike(locationId, locationUrl) {
    dispatch(dispatchLike(locationId, locationUrl));
  },
  dispatchUnlike(locationId, locationUrl) {
    dispatch(dispatchUnlike(locationId, locationUrl));
  },
  fetchCurrentUserTrue() {
    dispatch(fetchCurrentUserTrue());
  },
  fetchCurrentUser() {
    dispatch(fetchCurrentUser());
  },
});

export default connect(
  mapState,
  mapDispatch,
)(LocationLikeButton);
